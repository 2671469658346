.imageStyle {
    height: 150px;
    padding-right: 90px;
    padding-left: 90px;
    background-color: gray;
}

.imageStyle img {
    height: 100%;
    width: 100%
}

.logo-style {
    width: 150px;
    height: 40px
}

.testname {
    font-size: 25px;
    font-weight: 600
}

.fontsize-18 {
    font-size: 18px;
}

.fontsize-18-bold {
    font-size: 18px;
    font-weight: 600
}

.fontsize-20 {
    font-size: 20px;
}

.fontsize-20-bold {
    font-size: 20px;
    font-weight: 600
}

.fontsize-25 {
    font-size: 25px;
}

.testincluded {
    width: 100%;
    padding: 10px;
    background-color: #fbf8f1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsname {
    font-size: 20px;
}

.expand-grid {
    cursor: pointer;
}

.expand-details {
    width: 100%;
    border: 0;
    background-color: white;
    padding: 0
}

.packagedetails {
    border: 1px solid black;
    padding: 15px
}

.singleproduct {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 20px
}

.pincode-input {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px
}

.total-price {
    font-size: 20px;
}

.box-shadow {
    box-shadow: 2px 4px 14px 2px #00000026
}

.mbt-price {
    background-color: #e7f8f0;
    padding: 10px;
    margin-top: 10px;
    width: 150px;
    border-radius: 10px
}

.booknow-button {
    background-color: #02a44e;
    color: white;
    border: 0;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
}

.close-icon {
    color: green;
    cursor: pointer
}

.book-package-buttton {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #075eab;
    color: white;
    font-size: 25px;
    cursor: pointer;
}

.modalpopup-logo {
    width: 250px;
    height: 40px
}

.package-booking-form {
    font-size: 20px;
    font-weight: 600;
}

.form-control {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 20px;
    color: black
}

.book-now-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900;
    background-color: white;
    border-radius: 10px;
    padding: 4;
    box-shadow: 2px 4px 14px 2px #00000026
}

@media (max-width:1000px) {
    .book-now-form {
        width: 700;
    }

}

@media (max-width:900px) {
    .imageStyle {
        height: 200px;
        background-color: gray;
    }

    .book-now-form {
        width: 500;
    }

    .test-package-reserve {
        font-size: 13px;
        display: flex;
        justify-content: center;
        text-align: center;
    }

}

@media (max-width:700px) {
    .imageStyle {
        padding-right: 30px;
        padding-left: 30px;
    }

    .total-price {
        font-size: 16px;
    }

    .book-now-form {
        width: 400;
    }
}


@media (max-width:516px) {
    .logo-style {
        width: 100px;
        height: 30px
    }

    .imageStyle {
        height: 150px;
    }

    .mbt-price {
        padding: 5px;
        margin-top: 10px;
        width: 80px;
        border-radius: 5px;
        font-size: 15px
    }

    .fontsize-25 {
        font-size: 20px;
    }

    .detailsname {
        font-size: 15px;
    }

    .book-now-form {
        width: 200;
    }

    .modalpopup-logo {
        width: 100px;
        height: 30px
    }

    .package-booking-form {
        font-size: 15px;
    }

    .test-package-reserve {
        font-size: 8px;
    }

    .form-control {
        width: 100%;
        height: 25px;
        border-radius: 5px;
        padding-left: 10px;
        font-size: 15px;
        color: black
    }
    .book-package-buttton{
        height: 30px;
        font-size: 15px;
    }
}

@media (max-width:300px) {
    .book-now-form {
        width: 200;
    }

}