.popular-lab-test {
    font-size: 30px;
    font-weight: 400
}

.packageOff {
    border-radius: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #02a44e;
    color: white;
    display: flex;
    justify-content: center;
    width: 100px
}

.packageName1 {
    font-weight: 500;
    font-size: 17px;
    padding-top: 10px;
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.packageName2 {
    font-size: 16px;
    padding-top: 10px;
    height: 60px;
}

.package-rating {
    padding-top: 10px;
}

.package-price {
    font-size: 17px;
    font-weight: 800;
    padding-top: 10px
}

@media (max-width:600px) {
    .popular-lab-test {
        font-size: 20px;
        font-weight: 400
    }

}

@media (max-width:400px) {
    .popular-lab-test {
        font-size: 15px;
        font-weight: 400
    }

}
