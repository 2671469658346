.mainCaraousel {
    margin: 8rem auto;
    max-width: 100%;
  }
  .carouselItem {
    border-radius: 20px;
  }
  
  
  @media (max-width: 480px) {
    .carouselImgContainer {
      width: 90%;
    }
  
    .mainCaraousel {
      margin-bottom: 0rem;
    }
  
     .anchorWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }