.modal-box-styling{
    width: 1200px;
}

.select-city{
    font-size: 27px;
    font-weight: 700;
}

.modal-boxshadow{
    box-shadow: 2px 2px 12px 2px #00000026;
    border-radius: 10px;
    height: 400px;
    overflow-y: scroll; 
    padding-left: 20px;
    padding-right: 20px;
}

.modal-search-input{
    border-top:0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    width: 100%;
    padding: 10px;
}

.modal-search-input:focus {
    outline: none;
}

.modal-div-display-center{
    display: flex;
    align-items: center;
}

.modal-image-style{
    height: 100px;
    width: 100%;
}

.modal-image-style img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.modal-locations{
    cursor: pointer;
}

.modal-locations:hover{
    color:#02a44e;
}


.modal-location-avaiable{
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
    text-align: center;
}

.chip-width{
    width: 100%;
    height: 40px;
    cursor: pointer;
}

.font-size-20{
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
}

.icon-style{
    border-radius:10px;
    color:white;
    padding:10px;
    background-color:#02a44e;
    width:50px

}

@media(max-width:1300px){
    .modal-box-styling{
        width: 900px;
    }
}

@media(max-width:1000px){
    .modal-box-styling{
        width: 600px;
    }
}

@media(max-width:700px){
    .modal-box-styling{
        width: 400px;
    }
}

@media(max-width:500px){
    .modal-box-styling{
        width: 300px;
    }
}