.vconsulation-image {
    height: 400px;
    width: 100%;
}

.vconsulation-image img {
    height: 400px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid lightgreen;
}

.avail-unlimited-doctor {
    font-size: 25px;
    font-weight: 700;
    color: #075fab;
}

.verified-doctor-details{
    font-size: 20px;
    font-weight: 600;
}

.vconsult-input{
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.vconsult-input:focus {
    outline: none;
}
.mic-icon{
    background-color: white;
    border: 1px solid #02a44e;
}

.virtualdoctor-sideimage {
    height: 400px;
    width: 100%
}

.virtualdoctor-sideimage img {
    height: 400px;
    width: 100%
}

.vconsult-benefits {
    font-size: 25px;
    font-weight: 700;
    color: #075fab;
}

.vconsult-specilities {
    padding: 40px 0px 40px 0px;
    background-color:#f0fbf7 ;
}

.vconsult-specility {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 70px;
}

.virtual-consult-button{
    background-color: #02a44e;
    color: white;
    border: 1px solid #02a44e;
    border-radius: 5px 0px 0px 5px;
}

.clinic-consult-button{
    background-color: white;
    color: #02a44e;
    border: 1px solid #02a44e;
    border-radius: 0px 5px 5px 0px;
}

.vconsult-select{
    border-bottom: 1px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.filter-button{
    background-color: #075fab;
    color: white;
    border:0;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
}

.expand-grid {
    cursor: pointer;
}

.detailsname {
    font-size: 20px;
    text-align: left;
}

.FAQ-questions{
    width: 100%;
     background-color: white;
     border: none;
}

.answerdetails{
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #02a44e;
    padding: 20px;
    background-color: rgb(225, 255, 225);
    transition: all .8s;
}

.vconsullt-faq{
    font-size: 20px;
    font-weight: 500;
    color: #075fab;
    text-align: center;
}

.vconsult-anyqueries{
    font-size: 35px;
    font-weight: 600;
    color: #075fab;
    text-align: center;
}

/*benefit of virtual consult css */
.benefit-main {
    box-shadow: 2px 2px 14px 4px #00000026;
    border-radius: 10px;
}

.benefit {
    font-size: 20px;
    font-weight: 600;
    color: #075fab;
}

.benefit-explanation {
    font-size: 18px;
    padding-top: 10px;
}

/* virtual specility details css */
.specility-main {
    border: 1px solid green;
    border-radius: 10px;
}

.specility-main:hover {
    background-color: #02a44e;
    color: white;
    cursor: pointer;
    transition: all 0.5s;
}

.specility {
    font-size: 17px;
    margin-top: 50px;
    text-align: center;
}

.specility-conatiner {
    position: relative;
}

.specility-image {
    display: flex;
    justify-content: center;
}

.specility-image img {
    height: 120px;
    width: 120px;
    border-radius: 200px;
    position: absolute;
    top: -75px;
}

@media(max-width:680px){
    .vconsullt-faq{
        font-size: 20px;
    }

    .vconsult-anyqueries{
        font-size: 20px;
    }

    .detailsname {
        font-size: 14px;
        text-align: left;
    }

    .answerdetails{
        font-size: 9px;
    }
}