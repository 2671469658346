.happy-client-container {
    padding: 30px;
    background-color: #f0fbf7;
    margin-top: 50px;
}

.what-client-says {
    font-size: 25px;
}



/* happy client details css */
.happy-clients-main {
    box-shadow: 2px 2px 14px 2px #00000026;
    border-radius: 10px;
    height: 380px;
    padding: 20px 40px 10px 40px;
    margin: 16px 16px 16px 16px;
    background-color: white;
}

.happy-client-service {
    font-size: 25px;
    font-weight: 700;
}

.happy-client-description {
    height: 200px;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 300;
}

.happy-client-rating {
    padding: 10px;
    border-bottom: 1px solid black;
}

.client-details {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.happyclient-image {
    height: 60px;
    width: 60px;
}

.happyclient-image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px solid #02a44e;
}

.client-details-right {
    padding-left: 20px;
}

.client-name {
    font-size: 17px;
    font-weight: 600;
}

.client-designation {
    font-size: 16px;
}

@media (max-width:950px) {

    .happy-client-service {
        font-size: 20px;
    }

    .happy-client-description {
        font-size: 13px;
        height: 200px;
    }
}

@media (max-width:820px) {

    .happy-client-service {
        font-size: 18px;
    }

    .happy-client-description {
        font-size: 12px;
    }

    .happyclient-image {
        height: 50px;
        width: 50px;
    }

    .client-name {
        font-size: 14px;
        font-weight: 600;
    }

    .client-designation {
        font-size: 13px;
    }
}

@media (max-width:680px) {

    .happy-client-service {
        font-size: 22px;
    }

    .happy-client-description {
        font-size: 15px;
        height: 180px;
    }

    .happyclient-image {
        height: 60px;
        width: 60px;
    }

    .client-name {
        font-size: 18px;
        font-weight: 600;
    }

    .client-designation {
        font-size: 16px;
    }

    .what-client-says{
        font-size: 20px;
    }
}

@media (max-width:500px) {

    .happy-client-service {
        font-size: 18px;
    }

    .happy-client-description {
        font-size: 13px;
        height: 200px;
    }

    .happyclient-image {
        height: 50px;
        width: 50px;
    }

    .client-name {
        font-size: 14px;
        font-weight: 600;
    }

    .client-designation {
        font-size: 13px;
    }
}