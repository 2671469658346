.vdocotordetailsimage{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.vdocotordetailsimage img{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.vdoctorname{
    font-size: 20px;
    font-weight: 700;
    color: #075fab;
}

.vdoctordetails{
    font-size: 15px;
    color: black;
    padding-top: 5px;
}

.vdoctorbookappointment{
    width:100%;
    padding: 10px;
}

.vdoctorbookappointment button{
    padding:7px 12px 7px 12px;
    background-color: #075fab;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    border: 0;
    width:100%;
}

.vdoctorvirtualconsult{
    width:100%;
    padding: 10px;
}

.vdoctorvirtualconsult button{
    padding:7px 12px 7px 12px;
    background-color: #02a44e;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    border: 0;
    width:100%;
}