.footer-margin-top{
    margin-top: 150px;
}

.footer-upper-modal-height{
    margin-top: -100px; 
    background-color: green;
    border-radius: 15px;
    width: 85%;
}

.lets-talk-health{
    font-size: 25px;
}

.helpline-contact{
    font-size: 20px;
}

.get-in-touch-button{
    border-radius: 10px;
    width: 100%;
    background-color: white;
    color: #075fab;
    font-size: 20px;
    font-weight: 800;
    height: 50px;
    border: 0;
}

@media(max-width:600px){
    .footer-margin-top{
        margin-top: 180px;
    }
    
    .footer-upper-modal-height{
        margin-top: -150px; 
    }

    .lets-talk-health{
        font-size: 20px;
    }
    
    .helpline-contact{
        font-size: 13px;
    }
}