.drconsulation-carousel-style{
    margin-top: 40px;
     width: 100%;
     box-sizing: border-box
}

.specialist{
    font-size: 25px;
}

.artical{
    font-size: 25px;
    font-weight: 600;
}

.artical-details{
    font-size: 18px;
    margin-top: 10px;
}

.consulation{
    padding:15px;
    border-radius: 10px;
    box-shadow: 2px 4px 14px 2px #00000026;
    height: 250px;
    border-radius: 10px;
    cursor: pointer;
}

.consulation-image{
    width: 100%;
    height:170px
}

.image-style{
    height: 100%;
    width:100%;
    border-radius: 1px;
}

.opd-consulation{
    font-size: 25px;
    font-weight: 700;
    padding-top: 10px;
    color:#075fab ;
}

.top-specialist{
    font-size: 25px;
    text-align:center
}

@media (max-width:700px){
    .opd-consulation{
        font-size: 20px;
    }
    .top-specialist{
        font-size: 18px;
    }
}

@media (max-width:400px){
    .top-specialist{
        font-size: 14px;
    }
}