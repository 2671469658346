.opdconsulation-image {
    height: 400px;
    width: 100%;
}

.opdconsulation-image img {
    height: 400px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid lightgreen;
}

.opd-service-main {
    border-radius: 10px;
    box-shadow: 2px 4px 14px 2px #00000026;
    height: 250px;
    padding: 15px;
    cursor: pointer;
    color: #075fab;
}

.opd-service-main:hover {
    background-color: #02a44e;
    color: white;
}

.opd-service-image {
    height: 180px;
}

.opd-service-image-style {
    border-radius: 15px;
    height: 100%;
    width: 100%;
}

.opd-service-availibility {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
}

.opd-service-availibility:hover {
    color: white;
}

.opd-consulation-near-me {
    font-size: 25px;
    color: #075fab;
}

.opd-book-appointment {
    font-size: 20px;
}

.opd-consulation-select {
    width: 150px;
    font-size: 20px;
}

.opd-nearest-main-consulation {
    border-radius: 50%;
    border: 1px solid #02a44e;
    padding: 20px;
    height: 100px;
    display: flex;
    justify-content: center;
}

.opd-nearesr-image-style {
    border-radius: 50%;
    height: 100%
}

.opd-appointment-hospital-name {
    font-size: 22px;
    font-weight: 600;
}

.opd-book-appointment {
    display: flex;
    justify-content: right;
    padding-top: 20px;
}

.opd-book-button {
    font-size: 16px;
    background-color: #02a44e;
    color: white;
    padding: 7px 10px 7px 10px;
    border-radius: 5px;
}

@media(max-width:982px) {

    .opd-consulation-near-me {
        font-size: 20px;
    }
    .opd-book-appointment {
        font-size: 14px;
    }
}