.subheader-main{
    margin-top: 90px;
     background-color: #075fab
}

.subheader-container{
    color: white;
     cursor: pointer
}

.hover-div {
    width: 200px;
    height: 200px;
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .overlay-div {
    position: absolute;
    top: 19%;
    left: 5%;
    width: 170px;
    height: 100px;
    background-color: white; 
    font-size: 16px;
    font-weight: 800;
    color: black;
    z-index: 999; 
    box-shadow: 2px 2px 14px 4px #00000026;
    border-radius: 10px;
    padding: 10px;
  }
  
  .drconsulationlinks{
    color: black;
  }
  
  .drconsulationlinks:hover{
    color: black;
  }
  
  .drconsulationlinks div{
    margin-top: 5px;
  }