.category-container {
    padding: 30px;
    background-color: #f8f7fc;
    margin-top: 50px;
}


.women-care-border-radius {
    box-shadow: 2px 2px 12px 2px #00000026;
    border-radius: 10px;
}

.category-says{
    font-size: 25px;
}

.women-care-details {
    color: #075fab;
    cursor: pointer;
}

.women-care-details:hover {
    background-color: #02a44e;
    transition: background-color 0.5s;
}

.women-care-image {
    height: 100px;
    width: 100%;
}

.women-care-image img {
    height: 100%;
    width: 100%;
    border-radius: 7px;
}

.women-care-doctor-name {
    font-size: 20px;
    font-weight: 700;
    margin: 5px;
}

.women-care-health-image {
    height: 400px;
    width: 100%;
}

.women-care-health-image img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.women-care-health-doctor-name {
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 10px;
}

.women-care-health-detail {
    font-size: 16px;
    font-weight: 400;
}

.women-care-health-message {
    background-color: #ceefec;
    font-size: 17px;
    border-radius: 10px;
}

.women-care-package {
    font-size: 35px;
    font-weight: 700;
}

.women-care-package-image {
    width: 100%;
    height: 130px;
}

.women-care-package-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.women-care-package-name {
    color: #075fab;
    font-size: 22px;
    font-weight: 600;
    padding: 10px 0px 15px 0px;
}

.women-care-package-view-button button {
    background-color: #02a44e;
    color: white;
    border-radius: 7px;
    border: 0;
    padding: 7px 10px;
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
}

.women-care-package-details {
    background-color: white;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    height: 250px;
}

.women-care-package-category {
    font-size: 18px;
    font-weight: 600;
    color: #075fab;
    margin-top: 15px;
}

.category-says{
    text-align: center;
}

@media(max-width:600px){
    .women-care-package{
        font-size: 20px;
    }
    .women-care-health-doctor-name {
        font-size: 20px;
    }
    
}